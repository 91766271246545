<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <base-material-card color="indigo">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light" color="white">
              {{ $t('devices') }}
            </div>
          </template>
        </base-material-card>
        <devices-table :user="$route.params.slug" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UserDetails',
  components: {
    DevicesTable: () => import('@/views/dashboard/pages/devices/devices'),
  },
}
</script>
